import React from 'react';
import { Helmet, HelmetProvider } from "react-helmet-async";
import Header from '../components/GlodalComponents/Header/Header';
import Footer from '../components/GlodalComponents/Footer/Footer';
import Join from '../components/GlodalComponents/Join/Join';
import BannerBg from '../components/GlodalComponents/BannerBg/BannerBg';
import Banner from '../components/TradingPage/Banner/Banner';
import Slider from '../components/TradingPage/Slider/Slider';
import TextAcc from '../components/TradingPage/TextAcc/TextAcc';

function TradingPage() {
  return (
    <HelmetProvider>
      <Helmet>
        <title>Acomo · Trading Instruments</title>
        <meta name="title" content="Discover Acomo's Extensive Array of Trading Instruments for Unmatched Opportunities" />
        <meta property="og:title" content="Discover Acomo's Extensive Array of Trading Instruments for Unmatched Opportunities" />
        <meta property="twitter:title" content="Discover Acomo's Extensive Array of Trading Instruments for Unmatched Opportunities" />

        <meta name="description" content="Harness your trading potential with Acomo's broad selection of trading instruments, meticulously crafted to optimize your possibilities." />
        <meta property="og:description" content="Harness your trading potential with Acomo's broad selection of trading instruments, meticulously crafted to optimize your possibilities." />
        <meta property="twitter:description" content="Harness your trading potential with Acomo's broad selection of trading instruments, meticulously crafted to optimize your possibilities." />

        <meta property="og:image" content="preview.png" />
        <meta property="og:type" content="website" />
        <meta property="twitter:image" content="preview.png" />
        <meta property="twitter:card" content="summary_large_image" />
      </Helmet>
      <div className='trading-page ovf-hidden'>
        <Header />
        <Banner />
        <Slider />
        <TextAcc />
        <BannerBg />
        <Join />
        <Footer />
      </div>
    </HelmetProvider>
  )
  
}

export default TradingPage;
