import React, { useState } from 'react';
import { useParams } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";
import { Fade } from "react-reveal";
import styles from './Tabs.module.scss';

import icon from '../../../assets/images/icon-check.svg';

const Tabs = () => {
  const [activeTab, setActiveTab] = useState(0);
  const { t } = useTranslation();
  const { lang } = useParams();

  const handleTabClick = (id) => {
    setActiveTab(id);
  };

  const tabContent = t('acc.sec.tabs.titles', { returnObjects: true });
  const list = t('acc.sec.tabs.list', { returnObjects: true });

  return (
    <div className={`${styles.wrap} ${styles[lang]}`}>
      <div className={`${styles.container} container`}>
        <div className={styles.tabButtons}>
          {tabContent.map((tab, index) => (
            <Fade left delay={index * 100 + 1} key={index}>
              <button
                key={index}
                className={index === activeTab ? styles.tabActive : ''}
                onClick={() => handleTabClick(index)}
              >
                {t(`${tab.title}`)}
              </button>
            </Fade>
          ))}
        </div>

        <div className={styles.tabsContent}>
          <div className={styles.tabItemTitle}>{t('acc.sec.tabs.title')}</div>

          {list.map((item, index) => (
            <div className={`${styles.tabItem} ${index === activeTab ? styles.tabItemActive : ''}`} key={index}>
              <div className={styles.tabItemText}>{t(`${item.title}`)}</div>
              <div className={styles.tabList}>
                {item.list.map((list, listIndex) => (
                  <div className={styles.tabListItem} key={listIndex}>
                    <img src={icon} alt='' className={styles.tabListItemIcon} />
                    <div className={styles.tabListItemContent}>
                      <Trans>{list.title}</Trans>
                    </div>
                  </div>
                ))}
              </div>
              <div className={styles.tabItemText}>{t('acc.sec.tabs.text0')}</div>
              <div className={styles.tabItemText}>{t('acc.sec.tabs.text1')}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Tabs;