import React from 'react';
import { Helmet, HelmetProvider } from "react-helmet-async";
import Header from '../components/GlodalComponents/Header/Header';
import Footer from '../components/GlodalComponents/Footer/Footer';
import BannerBg from '../components/GlodalComponents/BannerBg/BannerBg';
import Join from '../components/GlodalComponents/Join/Join';
import Banner from '../components/SupportPage/Banner/Banner';
import TextAcc from '../components/SupportPage/TextAcc/TextAcc';
import TextGrid from '../components/SupportPage/TextGrid/TextGrid';
import Slider from '../components/SupportPage/Slider/Slider';
import FAQs from '../components/SupportPage/FAQs/FAQs';

function SupportPage() {
  return (
    <HelmetProvider>
      <Helmet>
        <title>Acomo · Support and FAQ</title>
        <meta name="title" content="Your Trading Questions, Our Expert Answers - Explore Acomo's Support and FAQ" />
        <meta property="og:title" content="Your Trading Questions, Our Expert Answers - Explore Acomo's Support and FAQ" />
        <meta property="twitter:title" content="Your Trading Questions, Our Expert Answers - Explore Acomo's Support and FAQ" />

        <meta name="description" content="Access the answers you seek through Acomo's extensive support and FAQ section, specifically designed to guide you on your trading expedition." />
        <meta property="og:description" content="Access the answers you seek through Acomo's extensive support and FAQ section, specifically designed to guide you on your trading expedition." />
        <meta property="twitter:description" content="Access the answers you seek through Acomo's extensive support and FAQ section, specifically designed to guide you on your trading expedition." />

        <meta property="og:image" content="preview.png" />
        <meta property="og:type" content="website" />
        <meta property="twitter:image" content="preview.png" />
        <meta property="twitter:card" content="summary_large_image" />
      </Helmet>
      <div className='faq-page ovf-hidden'>
        <Header />
        <Banner />
        <TextAcc />
        <TextGrid />
        <Slider />
        <FAQs />
        <BannerBg />
        <Join />
        <Footer />
      </div>
    </HelmetProvider>
  )
  
}

export default SupportPage;
