import React from "react";
import styles from './TextGrid.module.scss';
import { Trans, useTranslation } from "react-i18next";
import { Fade, Reveal } from "react-reveal";

import image from '../../../assets/images/homepage/image2.webp';

const TextGrid = () => {
  const { t } = useTranslation();
  const list1 = t('main.second.list1', { returnObjects: true });
  const list2 = t('main.second.list2', { returnObjects: true });

  return (
    <section className={styles.wrap}>
      <div className="container">
        <div className={styles.row}>
          <div className={styles.item}>
            <div className={`${styles.title} font-80-50`}>{t('main.second.title')}</div>
            <div className={`${styles.subtitle} font-45-28`}>{t('main.second.title1')}</div>
          </div>
          <div className={styles.item}>
            <div className={`${styles.text} font-16-14`}>{t('main.second.text')}</div>
          </div>
        </div>
        <div className={styles.listWrap}>
          <div className={styles.list}>
            {list1.map((item, index) => (
            <div className={`${styles.listItem} ${index === 0 ? styles.first : ''}`} key={index}>
              <Fade right delay={index * 100 + 1}>
                <div className={`${styles.listTitle} font-16`}>
                  <Trans>{t(`${item.title}`)}</Trans>
                </div>
              </Fade>
              </div>
            ))}
          </div>
          <Reveal effect="zoomIn" delay={100}>
            <div className="desk_only">
              <img src={image} alt="" className={styles.image} />
            </div>
          </Reveal>
          <div className={`${styles.list} ${styles.listLast}`}>
            {list2.map((item, index) => (
              <div className={styles.listItem} key={index}>
                <Fade right delay={index * 100 + 1}>
                  <div className={`${styles.listTitle} font-16`}>
                    <Trans>{t(`${item.title}`)}</Trans>
                  </div>
              </Fade>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default TextGrid;