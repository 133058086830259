import React from "react";
import { useParams } from "react-router-dom";
import styles from './Join.module.scss';
import { Trans, useTranslation } from "react-i18next";

const BannerBg = () => {
  const { t } = useTranslation();
  const { lang } = useParams();
  const list = t('main.nine.list', { returnObjects: true });

  return (
    <section className={`${styles.wrap} ${styles[lang]}`}>
      <div className={`${styles.container} container`}>
        <div className={`${styles.item} ${styles.itemLeft}`}>
          <div className={`${styles.title} font-80-70`}>{t('main.nine.title0')}</div>
          <div className={`${styles.subtitle} font-45-28`}><Trans>{t('main.nine.title1')}</Trans></div>
        </div>
        <div className={`${styles.item} ${styles.list}`}>
            {list.map((item, index) => (
              <div className={styles.listItem} key={index}>
                <div className={`${styles.listItemTitle} font-30-28`}>{t(`${item.title}`)}</div>
                <div className={`${styles.listItemText} font-16-14`}>{t(`${item.text}`)}</div>
              </div>
            ))}
        </div>
      </div>
    </section>
  );
};

export default BannerBg;