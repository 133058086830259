import React from "react";
import styles from './MarqueeList.module.scss';
import { useTranslation } from "react-i18next";
import Marquee from "react-fast-marquee";

const MarqueeList = () => {
  const { t } = useTranslation();
  const list1 = t('main.four.list1', { returnObjects: true });
  const list2 = t('main.four.list1', { returnObjects: true });

  return (
    <section className={styles.wrap}>
      <div className={`${styles.container} container`}>
        <Marquee autoFill>
          {list1.map((item, index) => (
            <div className={styles.item} key={index}>{t(`${item.title}`)}</div>
          ))}
        </Marquee>
        <Marquee autoFill direction="right">
          {list2.map((item, index) => (
            <div className={styles.item} key={index}>{t(`${item.title}`)}</div>
          ))}
        </Marquee>
      </div>
    </section>
  );
};

export default MarqueeList;