import React from "react";
import styles from './Banner.module.scss';
import { useTranslation } from "react-i18next";
import { Fade, Reveal } from "react-reveal";
import Button from "../../GlodalComponents/Button/Button";

import image from '../../../assets/images/homepage/image1.webp';
import imagemob from '../../../assets/images/homepage/image1-mob.webp';

const Banner = () => {
  const { t } = useTranslation();

  return (
    <section className={styles.wrap}>
      <div className={`${styles.container} container`}>
        <div className={styles.item}>
          <Fade left><div className={`${styles.title} font-115-70`}>{t('main.first.title')}</div></Fade>
          <Fade left delay={30}><div className={`${styles.subtitle} font-55-35`}>{t('main.first.subtitle')}</div></Fade>
          <Fade left delay={50}><div className={styles.mtBtn}><Button  /></div></Fade>
        </div>
        <div className={`${styles.item} ${styles.itemSec}`}>
          <Fade right delay={20}><div className={`${styles.text} font-16-14`}>{t('main.first.text0')}</div></Fade>
          <Fade right delay={40}><div className={`${styles.text} ${styles.textMt} font-16-14`}>{t('main.first.text1')}</div></Fade>
        </div>
        <div className={styles.imgWrap}>
          <Reveal effect="zoomIn" delay={100}>
            <div className="desk_only">
              <img src={image} alt="" className={styles.image} />
            </div>
            <div className="mob_only">
              <img src={imagemob} alt="" className={styles.image} />
            </div>
          </Reveal>
        </div>
      </div>
    </section>
  );
};

export default Banner;