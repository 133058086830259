import React from "react";
import { useParams } from "react-router-dom";
import styles from './Banner.module.scss';
import { Trans, useTranslation } from "react-i18next";
import Button from "../../GlodalComponents/Button/Button";

import image from '../../../assets/images/acctypes/image.webp';

const TextAcc = () => {
  const { t } = useTranslation();
  const { lang } = useParams();

  return (
    <section className={`${styles.wrap} ${styles[lang]}`}>
      <div className={`${styles.container} container`}>
        <div className={`${styles.item} ${styles.imgWrap}`}>
          <img src={image} alt="" />
        </div>
        <div className={styles.item}>
          <div className={`${styles.title} font-80-50`}><Trans>{t('acc.first.title')}</Trans></div>
          <div className={`${styles.text} font-16-14`}><Trans>{t('acc.first.text0')}</Trans></div>
          <div className={`${styles.text} font-16-14`}><Trans>{t('acc.first.text1')}</Trans></div>
          <div className="mt-btn desk_only"><Button /></div>
        </div>
      </div>
    </section>
  );
};

export default TextAcc;