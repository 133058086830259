import React from 'react';
import { Helmet, HelmetProvider } from "react-helmet-async";
import Header from '../components/GlodalComponents/Header/Header';
import Footer from '../components/GlodalComponents/Footer/Footer';
import BannerBg from '../components/GlodalComponents/BannerBg/BannerBg';
import Join from '../components/GlodalComponents/Join/Join';
import Accordion from '../components/GlodalComponents/Accordion/Accordion';
import Banner from '../components/AboutPage/Banner/Banner';
import TextImgGrid from '../components/AboutPage/TextImgGrid/TextImgGrid';

function AboutPage() {
  return (
    <HelmetProvider>
      <Helmet>
      <title>Acomo · About us</title>
        <meta name="title" content="Acomo: Leading the Way in a New Trading Era - Explore Our Vision and Dedication to Excellence" />
        <meta property="og:title" content="Acomo: Leading the Way in a New Trading Era - Explore Our Vision and Dedication to Excellence" />
        <meta property="twitter:title" content="Acomo: Leading the Way in a New Trading Era - Explore Our Vision and Dedication to Excellence" />

        <meta name="description" content="Delve deeper into Acomo, your companion in the ever-changing landscape of trading, where innovation and excellence steer our course." />
        <meta property="og:description" content="Delve deeper into Acomo, your companion in the ever-changing landscape of trading, where innovation and excellence steer our course." />
        <meta property="twitter:description" content="Delve deeper into Acomo, your companion in the ever-changing landscape of trading, where innovation and excellence steer our course." />

        <meta property="og:image" content="preview.png" />
        <meta property="og:type" content="website" />
        <meta property="twitter:image" content="preview.png" />
        <meta property="twitter:card" content="summary_large_image" />
      </Helmet>
      <div className='about-page ovf-hidden'>
        <Header />
        <Banner />
        <div className='container'><Accordion title="about.sec.list" /></div>
        <TextImgGrid />
        <BannerBg />
        <Join />
        <Footer />
      </div>
    </HelmetProvider>
  )
  
}

export default AboutPage;
