import React from 'react';
import { Helmet, HelmetProvider } from "react-helmet-async";
import Header from '../components/GlodalComponents/Header/Header';
import Footer from '../components/GlodalComponents/Footer/Footer';
import BannerBg from '../components/GlodalComponents/BannerBg/BannerBg';
import Join from '../components/GlodalComponents/Join/Join';
import Banner from '../components/FeesPage/Banner/Banner';
import TextList from '../components/FeesPage/TextList/TextList';
import Tabs from '../components/FeesPage/Tabs/Tabs';
import TextAcc from '../components/FeesPage/TextAcc/TextAcc';

function FeesPage() {
  return (
    <HelmetProvider>
      <Helmet>
        <title>Acomo · Fees and Commissions</title>
        <meta name="title" content="Navigating Fees and Commissions on Acomo" />
        <meta property="og:title" content="Navigating Fees and Commissions on Acomo" />
        <meta property="twitter:title" content="Navigating Fees and Commissions on Acomo" />

        <meta name="description" content="Learn about the comprehensive fee and commission details at Acomo, empowering you with transparency for your trading journey." />
        <meta property="og:description" content="Learn about the comprehensive fee and commission details at Acomo, empowering you with transparency for your trading journey." />
        <meta property="twitter:description" content="Learn about the comprehensive fee and commission details at Acomo, empowering you with transparency for your trading journey." />

        <meta property="og:image" content="preview.png" />
        <meta property="og:type" content="website" />
        <meta property="twitter:image" content="preview.png" />
        <meta property="twitter:card" content="summary_large_image" />
      </Helmet>
      <div className='fees-page ovf-hidden'>
        <Header />
        <Banner />
        <TextList />
        <Tabs />
        <TextAcc />
        <BannerBg />
        <Join />
        <Footer />
      </div>
    </HelmetProvider>
  )
}

export default FeesPage;
