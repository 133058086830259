import HomePage from '../pages/HomePage';
import AccountsPage from '../pages/AccountsPage';
import AboutPage from '../pages/AboutPage';
import TradingPage from '../pages/TradingPage';
import FeesPage from '../pages/FeesPage';
import SupportPage from '../pages/SupportPage';

export const routes = [
    { path: '/:lang/', element: HomePage },
    { path: '/:lang/trading', element: TradingPage },
    { path: '/:lang/fees', element: FeesPage },
    { path: '/:lang/accounts', element: AccountsPage },
    { path: '/:lang/support', element: SupportPage },
    { path: '/:lang/about', element: AboutPage },
];


export const headerRoutes = [
    { path: 'trading', text: 'header.trading' },
    { path: 'fees', text: 'header.fees' },
    { path: 'accounts', text: 'header.accounts' },
    { path: 'support', text: 'header.support' },
    { path: 'about', text: 'header.about' },
];