import React from 'react';
import { useTranslation } from "react-i18next";
import logo from '../../../assets/images/footer-logo.svg';
import styles from './Footer.module.scss';

const Footer = () => {
  const { t } = useTranslation();
  const { hostname } = document.location;

  return (
    <footer className={styles.footer}>
      <div className='container'>
        <div className={styles.footerContent}>
          <div className={styles.leftSide}>
            <div className={styles.logoWrap}>
              <img src={logo} alt="Logo" className={styles.headerLogo} />
            </div>
            <div className={styles.footerNav}>
              <div className={styles.itemBlock} >
                <div className={`${styles.itemBlock} ${styles.navTitle}`}>
                  Beursplein 37, 21e etage, Rotterdam, Netherlands
                </div>
                <div className={styles.itemBlock}>
                  <a href="tel:+ 441224076141" className={styles.navTitle}>+ 441224076141</a>
                </div>
                <div className={styles.itemBlock}>
                  <a href={`mailto:support@${hostname}`} className={styles.navTitle}>
                    support@{hostname}
                  </a>
                </div>
              </div>
              <div className={styles.itemBlock}>
                <a  
                  href="/docs/CONFLICT_OF_INTERESTS.pdf"
                  target="_blank"
                  className={styles.navTitle}
                >
                  {t('footer.docs.conflict')}
                </a>
                <a
                  href="/docs/PRIVACY_POLICY.pdf"
                  target="_blank"
                  className={styles.navTitle}
                >
                  {t('footer.docs.privacy')}
                </a>
                <a  
                  href="/docs/CLIENT_AGREEMENТ.pdf"
                  target="_blank"
                  className={styles.navTitle}
                >
                  {t('footer.docs.client_ag')}
                </a>
              </div>
            </div>
          </div>
          <div className={styles.rightSide}>
            <div className={styles.navTitle}>{t('footer.disclaimer.title')}</div>
            <div>
              <p className={styles.text}>{t('footer.disclaimer.text0')}</p>
              <p className={styles.text}>{t('footer.disclaimer.text1')}</p>
            </div>
            {/* <div className={styles.copyright}>{t('footer.rights')}</div> */}
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;