import React from "react";
import { useParams } from "react-router-dom";
import styles from './FAQs.module.scss';
import { Trans, useTranslation } from "react-i18next";
import Accordion from '../../GlodalComponents/Accordion/Accordion';

const FAQs = () => {
  const { t } = useTranslation();
  const { lang } = useParams();

  return (
    <section className={`${styles.wrap} ${styles[lang]}`}>
      <div className={`${styles.container} container`}>
        <div className={`${styles.title} ${styles.item} font-80-50`}><Trans>{t('support.five.title')}</Trans></div>
        <div className={styles.accwrap}>
          <Accordion title={'support.five.list'} />
        </div>
      </div>
    </section>
  );
};

export default FAQs;