import React from "react";
import { useParams } from "react-router-dom";
import styles from './Banner.module.scss';
import { Trans, useTranslation } from "react-i18next";
import Button from "../../GlodalComponents/Button/Button";

import image from '../../../assets/images/support/image.webp';
import imagemob from '../../../assets/images/support/image-mob.webp';

const TextAcc = () => {
  const { t } = useTranslation();
  const { lang } = useParams();

  return (
    <section className={`${styles.wrap} ${styles[lang]}`}>
      <img src={image} alt="" className={`${styles.imgDesk} desk_only`} />
      <div className={`${styles.container} container`}>
        <div className={`${styles.item} ${styles.imgWrap}`}>
          <img src={imagemob} alt="" className={`${styles.imgWrap} mob_only`} />
        </div>
        <div className={styles.item}>
          <div className={`${styles.title} font-80-50`}><Trans>{t('support.first.title0')}</Trans></div>
          <div className={`${styles.subtitle} font-55-35 desk_only`}><Trans>{t('support.first.title1')}</Trans></div>
          <div className={`${styles.text} font-16-14`}><Trans>{t('support.first.text0')}</Trans></div>
          <div className={`${styles.text} font-16-14`}><Trans>{t('support.first.text1')}</Trans></div>
          <div className="mt-btn desk_only"><Button white /></div>
          <div className={`${styles.centered} mt-btn mob_only`}><Button /></div>
        </div>
      </div>
    </section>
  );
};

export default TextAcc;