import React from "react";
import styles from './Banner.module.scss';
import { Trans, useTranslation } from "react-i18next";
import Button from "../../GlodalComponents/Button/Button";

import image1 from '../../../assets/images/trading/image1.webp';
import image2 from '../../../assets/images/trading/image2.webp';
import icon from '../../../assets/images/icon-check.svg';

const TextAcc = () => {
  const { t } = useTranslation();
  const list = t('trading.first.list', { returnObjects: true })

  return (
    <section className={styles.wrap}>
      <div className={`${styles.container} container`}>
        <div className={`${styles.row} ${styles.rowFirst}`}>
          <div className={styles.rowItem}>
            <div className={`${styles.title} font-80-50`}><Trans>{t('trading.first.title')}</Trans></div>
            <div className={`${styles.text} font-16-14`}><Trans>{t('trading.first.text0')}</Trans></div>
          </div>
          <div className={`${styles.rowItem} ${styles.imgWrap} desk_only`}>
            <img src={image1} alt="" />
          </div>
        </div>
        <div className={`${styles.row} ${styles.rowSec}`}>
          <div className={`${styles.rowItem} ${styles.imgWrap} desk_only`}>
            <img src={image2} alt="" />
          </div>
          <div className={styles.rowItem}>
            <div className={styles.list}>
              {list.map((item, index) => (
                <div className={`${styles.listItem} font-16-14`} key={index}>
                  <img src={icon} alt="" className={styles.listItemIcon} />
                  <div>{t(`${item.title}`)}</div>
                </div>
              ))}
            </div>
            <div className={`${styles.text} font-16-14`}><Trans>{t('trading.first.text1')}</Trans></div>
            <div className="mt-btn"><Button /></div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TextAcc;