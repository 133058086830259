import React from "react";
import { useParams, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import styles from './Button.module.scss';

const Button = ({ children, login, href, event, pink, white }) => {
  const { t } = useTranslation();
  const { lang } = useParams();

  if (event) {
    return (
      <div
        onClick={event}
        className={`${styles.button} ${pink ? styles.pink : ''} ${white ? styles.white : ''} no-select`}
      >
        {children}
      </div>
    );
  }

  return (
    <Link
      className={`${styles.button} ${pink ? styles.pink : ''} ${white ? styles.white : ''} no-select`}
      to={!href
        ? `${process.env.REACT_APP_CABINET_URL}/${login ? 'login' : 'signup'}?lang=${lang}`
        : `/${lang}/${href}`
      }
    >
      {children
        ? children
        : t('btn.start')
      }
    </Link>
  );
};

export default Button;