import React from "react";
import styles from './TextImage.module.scss';
import { useTranslation } from "react-i18next";
import { Fade } from "react-reveal";

import image from '../../../assets/images/homepage/image3.webp';

const TextImage = () => {
  const { t } = useTranslation();

  return (
    <section className={styles.wrap}>
      <div className={`${styles.container} container`}>
        <div className={styles.item}>
          <Fade left><div className={`${styles.title} font-80-50`}>{t('main.third.title0')}</div></Fade>
          <Fade left delay={30}><div className={`${styles.subtitle} font-45-28`}>{t('main.third.title1')}</div></Fade>
          <Fade left delay={60}><div className={`${styles.text} font-16-14`}>{t('main.third.text0')}</div></Fade>
          <Fade left delay={90}><div className={`${styles.text} ${styles.textMt} font-16-14`}>{t('main.third.text1')}</div></Fade>
        </div>
        <div className={styles.imgWrap}>
          <Fade right delay={100}>
            <div>
              <img src={image} alt="" className={styles.image} />
            </div>
          </Fade>
        </div>
      </div>
    </section>
  );
};

export default TextImage;