import React from "react";
import styles from './TextList.module.scss';
import { Trans, useTranslation } from "react-i18next";
import { Fade } from "react-reveal";
import Button from "../../GlodalComponents/Button/Button";

import icon from '../../../assets/images/icon-check.svg';

const TextList = () => {
  const { t } = useTranslation();
  const list1 = t('main.seven.list', { returnObjects: true });

  return (
    <section className={styles.wrap}>
      <div className={`${styles.container} container`}>
        <div className={styles.row}>
          <div className={styles.item}>
            <div className={`${styles.title} font-80-50`}>{t('main.seven.title0')}</div>
            <div className={`${styles.subtitle} font-45-28`}>{t('main.seven.title1')}</div>
            <div className={`${styles.text} font-16-14`}>{t('main.seven.text0')}</div>
            <div className={styles.list}>
            {list1.map((item, index) => (
            <div className={`${styles.listItem} ${index === 0 ? styles.first : ''}`} key={index}>
              <Fade right delay={index * 100 + 1}>
                <div className={`${styles.listTitle} font-16`}>
                  <img src={icon} alt="" className={styles.icon} />
                  <Trans>{t(`${item.title}`)}</Trans>
                </div>
              </Fade>
              </div>
            ))}
          </div>
          </div>
          <div className={styles.item}>
            <div className={`${styles.text} font-16-14`}>{t('main.seven.text1')}</div>
            <div className="mt-btn"><Button /></div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TextList;