import React from 'react';
import { Helmet, HelmetProvider } from "react-helmet-async";
import Header from '../components/GlodalComponents/Header/Header';
import Footer from '../components/GlodalComponents/Footer/Footer';
import Banner from '../components/HomePage/Banner/Banner';
import TextGrid from '../components/HomePage/TextGrid/TextGrid';
import TextImage from '../components/HomePage/TextImage/TextImage';
import MarqueeList from '../components/HomePage/MarqueeList/MarqueeList';
import TextImgGrid from '../components/HomePage/TextImgGrid/TextImgGrid';
import TextListLink from '../components/HomePage/TextListLink/TextListLink';
import TextList from '../components/HomePage/TextList/TextList';
import BannerBg from '../components/GlodalComponents/BannerBg/BannerBg';
import Join from '../components/GlodalComponents/Join/Join';

function HomePage() {
  return (
    <HelmetProvider>
      <Helmet>
        <title>Acomo</title>
        <meta name="title" content="Welcome to Acomo - Where Innovation and Trading Excellence Converge!" />
        <meta property="og:title" content="Welcome to Acomo - Where Innovation and Trading Excellence Converge!" />
        <meta property="twitter:title" content="Welcome to Acomo - Where Innovation and Trading Excellence Converge!" />

        <meta name="description" content="Discover a realm of trading innovation and excellence at Acomo, where every trader embarks on their journey." />
        <meta property="og:description" content="Discover a realm of trading innovation and excellence at Acomo, where every trader embarks on their journey." />
        <meta property="twitter:description" content="Discover a realm of trading innovation and excellence at Acomo, where every trader embarks on their journey." />

        <meta property="og:image" content="preview.png" />
        <meta property="og:type" content="website" />
        <meta property="twitter:image" content="preview.png" />
        <meta property="twitter:card" content="summary_large_image" />
      </Helmet>
      <div className='main-page ovf-hidden'>
        <Header />
        <Banner />
        <TextGrid />
        <TextImage />
        <MarqueeList />
        <TextImgGrid />
        <TextListLink />
        <TextList />
        <BannerBg />
        <Join />
        <Footer />
      </div>
    </HelmetProvider>
  )
}

export default HomePage;
