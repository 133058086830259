import React from "react";
import { useParams } from "react-router-dom";
import styles from './BannerBg.module.scss';
import { useTranslation } from "react-i18next";
import { Fade, Reveal } from "react-reveal";
import Button from "../Button/Button";

import image from '../../../assets/images/image.webp';

const BannerBg = () => {
  const { t } = useTranslation();
  const { lang } = useParams();

  return (
    <section className={`${styles.wrap} ${styles[lang]}`}>
      <div className={`${styles.container} container`}>
        <div className={styles.item}>
          <Fade left><div className={`${styles.title} font-80-70`}>{t('main.eight.title0')}</div></Fade>
          <Fade left delay={30}><div className={`${styles.subtitle} font-45-28`}>{t('main.eight.title1')}</div></Fade>
          <Fade left delay={20}><div className={`${styles.text} font-16-14`}>{t('main.eight.text')}</div></Fade>
          <Fade left delay={50}><div className={styles.mtBtn}><Button  /></div></Fade>
        </div>
        <div className={`${styles.item} ${styles.imgWrap}`}>
          <Reveal effect="zoomIn" delay={100}>
            <div>
              <img src={image} alt="" className={styles.image} />
            </div>
          </Reveal>
        </div>
      </div>
    </section>
  );
};

export default BannerBg;