import React, { useState } from "react";
import styles from './Slider.module.scss';
import { Trans, useTranslation } from "react-i18next";
import Button from "../../GlodalComponents/Button/Button";
import { Navigation } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';

import icon from '../../../assets/images/icon-link.svg';

const Slider = () => {
  const { t } = useTranslation();
  const [swiper, setSwiper] = useState({});
  const [active, setActive] = useState(0);
  const slides = t('support.four.slider', { returnObjects: true });

  return (
      <section className={styles.wrap}>
        <div className={`${styles.container} container`}>
          <div className={styles.blockText}>
            <div className={`${styles.title} font-80-50`}><Trans>{t('support.four.title')}</Trans></div>
            <div className={`${styles.text} font-16-14`}><Trans>{t('support.four.text')}</Trans></div>
          </div>
          <div className={styles.sliderWrap}>
            <div className={styles.info}>
              <div className={styles.count}>
                <span>{active + 1}/</span>{slides.length}
              </div>
              <div className={styles.sliderBtns}>
                <button 
                  className={`${styles.sliderBtn} ${styles.sliderBtnPrev} ${active === 0 ? styles.inactive : ''}`}
                  onClick={() => swiper.slidePrev()}
                >
                  <img src={icon} alt="Prev" />
                </button>
                <button 
                  className={`${styles.sliderBtn} ${styles.sliderBtnNext} ${active === swiper.snapGrid?.length - 1 ? styles.inactive : ''}`}
                  onClick={() => swiper.slideNext()}
                >
                  <img src={icon} alt="Next" />
                </button>
              </div>
            </div>
            <Swiper
              modules={[Navigation]}
              spaceBetween={19}
              slidesPerGroup={1}
              loop={false}
              breakpoints={{
                0: {
                  slidesPerView: 1,
                },
                769: {
                  slidesPerView: 1.2,
                },
                1024: {
                  slidesPerView: 3.2,
                }
              }}
              onInit={(e) => { setSwiper(e) }}
              onSlideChange={(e) => setActive(e.activeIndex)}
            >
              {slides.map((item, index) => (
                <SwiperSlide key={index} className={styles.sliderItem}>
                  <div className={`${styles.slideTitle} font-30-28`}>{item.title}</div>
                  <div className={`${styles.slideText} font-16-14`}>{item.text}</div>
                  <div className={styles.btn}><Button /></div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      </section>
  );
};

export default Slider;