import React from "react";
import { useParams } from "react-router-dom";
import styles from './TextAcc.module.scss';
import { Trans, useTranslation } from "react-i18next";
import Accordion from '../../GlodalComponents/Accordion/Accordion';

const TextAcc = () => {
  const { t } = useTranslation();
  const { lang } = useParams();

  return (
    <section className={`${styles.wrap} ${styles[lang]}`}>
      <div className={`${styles.container} container`}>
        <div className={styles.row}>
            <div className={`${styles.title} ${styles.item} font-80-50`}><Trans>{t('acc.third.title')}</Trans></div>
            <div className={`${styles.text} ${styles.item} font-16-14`}><Trans>{t('acc.third.text')}</Trans></div>
        </div>
        <div className={styles.accwrap}>
          <Accordion title={'acc.third.list'} />
        </div>
      </div>
    </section>
  );
};

export default TextAcc;