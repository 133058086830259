import React from "react";
import styles from './Banner.module.scss';
import { Trans, useTranslation } from "react-i18next";
import Button from "../../GlodalComponents/Button/Button";

import image from '../../../assets/images/about/image1.webp';

const Banner = () => {
  const { t } = useTranslation();

  return (
    <section className={styles.wrap}>
      <div className={`${styles.container} container`}>
        <div className={styles.item}>
          <div className={`${styles.title} font-80-50`}><Trans>{t('about.first.title')}</Trans></div>
          <div className={`${styles.text} font-16-14`}><Trans>{t('about.first.text0')}</Trans></div>
          <div className={`${styles.text} font-16-14`}><Trans>{t('about.first.text1')}</Trans></div>
          <div className="mt-btn desk_only"><Button /></div>
        </div>
        <div className={`${styles.item} ${styles.imgWrap}`}>
          <img src={image} alt="" />
        </div>
      </div>
    </section>
  );
};

export default Banner;