import React from 'react';
import { Helmet, HelmetProvider } from "react-helmet-async";
import Header from '../components/GlodalComponents/Header/Header';
import Footer from '../components/GlodalComponents/Footer/Footer';
import BannerBg from '../components/GlodalComponents/BannerBg/BannerBg';
import Join from '../components/GlodalComponents/Join/Join';
import Banner from '../components/AccountsPage/Banner/Banner';
import Tabs from '../components/AccountsPage/Tabs/Tabs';
import TextAcc from '../components/AccountsPage/TextAcc/TextAcc';

function AccountsPage() {
  return (
    <HelmetProvider>
      <Helmet>
        <title>Acomo · Account types</title>
        <meta name="title" content="Discover Your Perfect Trading Account with Acomo - Tailored Solutions for Every Trader" />
        <meta property="og:title" content="Discover Your Perfect Trading Account with Acomo - Tailored Solutions for Every Trader" />
        <meta property="twitter:title" content="Discover Your Perfect Trading Account with Acomo - Tailored Solutions for Every Trader" />

        <meta name="description" content="Explore Acomo and find the ideal trading account that suits your individual needs. Our platform offers customized solutions, ensuring every trader has the perfect fit for their trading journey." />
        <meta property="og:description" content="Explore Acomo and find the ideal trading account that suits your individual needs. Our platform offers customized solutions, ensuring every trader has the perfect fit for their trading journey." />
        <meta property="twitter:description" content="Explore Acomo and find the ideal trading account that suits your individual needs. Our platform offers customized solutions, ensuring every trader has the perfect fit for their trading journey." />

        <meta property="og:image" content="preview.png" />
        <meta property="og:type" content="website" />
        <meta property="twitter:image" content="preview.png" />
        <meta property="twitter:card" content="summary_large_image" />
      </Helmet>
      <div className='account-page ovf-hidden'>
        <Header />
        <Banner />
        <Tabs />
        <TextAcc />
        <BannerBg />
        <Join />
        <Footer />
      </div>
    </HelmetProvider>
  )
  
}

export default AccountsPage;
