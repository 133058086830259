import React from "react";
import styles from './TextList.module.scss';
import { Trans, useTranslation } from "react-i18next";

import icon from '../../../assets/images/icon-check.svg';

const TextAcc = () => {
  const { t } = useTranslation();
  const list = t('fees.sec.list', { returnObjects: true })

  return (
    <section className={styles.wrap}>
      <div className={`${styles.container} container`}>
        <div className={styles.item}>
          <div className={`${styles.title} font-80-50`}><Trans>{t('fees.sec.title')}</Trans></div>
          <div className={`${styles.text} font-16-14`}><Trans>{t('fees.sec.text0')}</Trans></div>
          <div className={`${styles.listTitle} font-16-14`}><Trans>{t('fees.sec.list.title')}</Trans></div>
          <div className={styles.list}>
            {list.map((item, index) => (
              <div className={`${styles.listItem} font-16`} key={index}>
                <img src={icon} alt="" className={icon} />
                {t(`${item.title}`)}
              </div>
            ))}
          </div>
        </div>
        <div className={styles.item}>
          <div className={`${styles.text} font-16-14`}><Trans>{t('fees.sec.text1')}</Trans></div>
          <div className={`${styles.text} font-16-14`}><Trans>{t('fees.sec.text2')}</Trans></div>
        </div>
      </div>
    </section>
  );
};

export default TextAcc;