import React from "react";
import { useParams, Link } from "react-router-dom";
import styles from './TextListLink.module.scss';
import { Trans, useTranslation } from "react-i18next";
import { Fade } from "react-reveal";
import Button from "../../GlodalComponents/Button/Button";

import icon from '../../../assets/images/icon-link.svg';

const TextListLink = () => {
  const { t } = useTranslation();
  const { lang } = useParams();
  const list1 = t('main.six.list', { returnObjects: true });

  return (
    <section className={styles.wrap}>
      <div className={`${styles.container} container`}>
        <div className={styles.item}>
          <div className={`${styles.title} font-45-28`}>{t('main.six.title0')}</div>
          <div className={`${styles.text} font-16-14`}>{t('main.six.text0')}</div>
          <div className={`${styles.text} font-16-14`}>{t('main.six.text1')}</div>
          <div className="mt-btn"><Button /></div>
        </div>
        <div className={styles.item}>
          <div className={styles.list}>
            {list1.map((item, index) => (
            <div className={`${styles.listItem} ${index === 0 ? styles.first : ''}`} key={index}>
              <Fade right delay={index * 100 + 1}>
                <Link
                  className={`${styles.button} font-30-16 no-select`}
                  to={`${process.env.REACT_APP_CABINET_URL}/login?lang=${lang}`}
                >
                   <img src={icon} alt="" className={styles.icon} />
                   <Trans>{t(`${item.title}`)}</Trans>
                </Link>
              </Fade>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default TextListLink;